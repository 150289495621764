import React from 'react';
import { OpenfitCartCrossSellFragment } from 'graphql-types';
import { renderBlockWithId } from 'helpers/block-with-id';
import { BlockType } from 'types';
import { Cart } from 'api/cart/cart.api.model';
import { Spacer } from 'components/Spacer/Spacer';
import { CartCrossSell } from 'components/Cart/CartCrossSell/CartCrossSell';

import {
  OpenfitCartPageBySlugQuery,
  SanitySellPageSpacer,
} from 'graphql-types';

export type BlocksArrayType = NonNullable<
  OpenfitCartPageBySlugQuery['page']
>['blocks'];

export type Block = BlockType<BlocksArrayType>;
export type CartBlockRenderer = React.FC<{ block: Block; cart?: Cart }>;

export const renderCartPageBlock: CartBlockRenderer = ({ block, cart }) =>
  renderBlockWithId(block, getBlock({ block, cart }));

const getBlock: CartBlockRenderer = ({ block, cart }) => {
  const type = block.__typename;
  const id = block._key;

  switch (type) {
    case 'SanitySellPageSpacer':
      return (
        <Spacer
          key={id}
          backgroundColor={
            (block as SanitySellPageSpacer)?.backgroundColor?.hex
          }
          margin={(block as SanitySellPageSpacer)?.margin}
          marginMobile={(block as SanitySellPageSpacer)?.marginMobile}
        />
      );
    case 'SanityOpenfitCartCrossSell':
      return (
        <CartCrossSell
          cart={cart}
          crossSell={(block as unknown) as OpenfitCartCrossSellFragment}
        />
      );
    default:
      return <div>{`Unrecognized block type: ${type}`}</div>;
  }
};

import React from 'react';
import { graphql } from 'gatsby';
import { CartPage } from 'components/Cart/CartPage/CartPage';
import { renderCartPageBlock, Block } from './CartPage.blocks';
import { OpenfitCartPageBySlugQuery } from 'graphql-types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: { data: OpenfitCartPageBySlugQuery }) => {
  const { blocks } = props.data?.page || {};
  return (
    <CartPage blocks={blocks as Block[]} BlockRenderer={renderCartPageBlock} />
  );
};

export const query = graphql`
  query OpenfitCartPageBySlug($slug: String!) {
    page: sanityOpenfitCartPage(slug: { current: { eq: $slug } }) {
      name
      slug {
        current
      }
      seoMetadata {
        ...SeoMetadata
      }
      blocks {
        ...SanitySellPageSpacer
        ...OpenfitCartCrossSell
      }
    }
  }
`;
